import React, { useState, useEffect } from "react";
import ShedulerDropdown from "../../Dropdown/Dropdown";
import { GoClock } from "react-icons/go";
import NotificationPopus from "../../Popup/Notification";
import { isMobile } from "react-device-detect";
import "../Upcoming/Upcoming.css";
import moment from "moment";
import momentduration from "moment-duration-format";

const UpcomingCard = ({ item }) => {
  const [modalShow, setPopupShow] = React.useState(false);
  const [enablePopup, setEnablePopup] = useState(false);
  const [notifdata, setnotifdata] = React.useState([]);
  // const handlePopus = (event) => {
  //   console.log(event.target.id);
  //   // PopupStyle.current.style.opacity = "0";
  //   setPopupShow(true);
  //   setEnablePopup(true);
  //   for(var i=0;i<props.upcomingdata.length;i++)
  //   {
  //     if(props.upcomingdata[i].eventId==event.target.parentElement.id)
  //     {
  //       setnotifdata(props.upcomingdata[i]);
  //     }
  //   }

  // };

  return (
    // <>{isMobile ? <NotificationPopus show={modalShow} onHide={() => setPopupShow(false)} notifdata={notifdata}/> : null}
    // {
    // props.upcomingdata.map(item =>(item.Status_Val=="accept") &&item.RequestStart_DtTm>= (new Date().toISOString())?
    // (
    <>
      <div
        className="col-md-12 col-lg-4"
        // onClick={(e) =>handlePopus(e)}
      >
        <div className="card card-conatiner card-border-meeting">
          <div className="card-head scheduler-card">
            {isMobile ? (
              <h3>{moment(item.RequestStart_DtTm).format("D MMM")}</h3>
            ) : (
              <h2>{moment(item.RequestStart_DtTm).format("D MMM")}</h2>
            )}

            {/* <ShedulerDropdown /> */}
          </div>
          <div id={item.eventId} className="card-body">
            <h4>{item.Subject_Val}</h4>
            <div className="card-body-head  mb-3">
              <GoClock />
              <span className="">
                Duration :{" "}
                {moment
                  .duration(
                    moment(item.RequestedEnd_DtTm).diff(
                      item.RequestStart_DtTm
                    ) / 1000,
                    "seconds"
                  )
                  .format("h [hrs], m [min]")}
              </span>
            </div>
            <div className="card-body-text mb-3">
              {item.Description_Val === ""
                ? "Description"
                : item.Description_Val}
            </div>
          </div>
          <div className="card-footer">
            <div className="bg-light-grey footer-duration primary-text mb-4">
              {moment(item.RequestStart_DtTm).format("h:mm a")} -{" "}
              {moment(item.RequestedEnd_DtTm).format("h:mm a")}
            </div>
          </div>
        </div>
      </div>
      {/* ):null) } */}
    </>
  );
};
export default UpcomingCard;
