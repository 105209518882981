import React from 'react'
import ShedulerDropdown from "../../Dropdown/Dropdown";
import { GoClock } from "react-icons/go";
import '../Pending/Pending.css'
import { isMobile } from 'react-device-detect';
import moment from 'moment';

const PendingCard = ({item}) => {
  // console.log(props);
  return (
    <>
    {/* {
    props.pendingdata.map(item => item.Status_Val=='request' &&item.RequestStart_DtTm>= (new Date().toISOString())? ( */}
            
            <div className="col-md-12 col-lg-4">
              <div className="card card-conatiner card-border-upcoimg-meeting">
                <div className="card-head scheduler-card justify-content-between">
                {isMobile?<h3 className="text-yellow">{moment(item.RequestStart_DtTm).format('D MMM')}</h3>: <h2 className="text-yellow">{moment(item.RequestStart_DtTm).format('D MMM')}</h2>}
                  {/* <ShedulerDropdown /> */}
                </div>
                <div className="card-body">
                  <h4>{item.Subject_Val}</h4>
                  <div className="card-body-head  mb-3">
                    <GoClock />
                    <span className="">Duration : {moment.duration(((moment(item.RequestedEnd_DtTm).diff(item.RequestStart_DtTm))/1000), "seconds").format("h [hrs], m [min]")}</span>
                  </div>
                  <div className="card-body-text mb-3">
                     {item.Description_Val===""?"Description":item.Description_Val}
                  </div>
                  <div className="card-footer">
                    <div className="card-footer-content footer-duration bg-yellow mb-4">
                    {moment(item.RequestStart_DtTm).format('h:mm a')} - {moment(item.RequestedEnd_DtTm).format('h:mm a')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
     {/* ):null) } */}
     </>
  )
}
export default PendingCard;
