import React, { useState, useEffect } from "react";
import "../Form/form.css";
import {useLocation} from 'react-router-dom';
import {apiHandler}from '../APIhandler/apiHandler';
import { apiList } from "../../apiList";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import { isMobile } from "react-device-detect";
import TimePicker from "rc-time-picker"
import "rc-time-picker/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";

export default function CreateEventForm(props) {
  const { addOrEdit, recordForEdit } = props;
  const [name, setName] = useState("");
  const [startdate, setStartDate] = useState("");
  const [formresult, setformResult] = useState("");
  const [enddate, setEndDate] = useState("");
  const [desc, setDesc] = useState("");
  const [type, setType] = useState("Dictamen");
  const search = useLocation().search; 
  const [IdVar,setIdVar]= useState("");
  const [error,setError]=useState(false)
  const [buttonDisable,setButtonDisable]=useState(false);
  const [lessThanCurrent,setLessThanCurrent]=useState(false);
  const [inValidDate,setInValidDate]=useState(false);
  const [creating,setCreating]=useState(false);
  const [endTimeRadio, setEndTimeRadio] = useState("15");
  const [tempTimeStart,setTempTimeStart]=useState()
  const [tempTimeEnd,setTempTimeEnd]=useState()
  
  const handleSubmit = (event) => {
    setButtonDisable(true);
    const Id =  IdVar
    event.preventDefault();
    let Edate=tempTimeEnd;
     if(endTimeRadio!="other"){
    Edate=moment(tempTimeStart).add(endTimeRadio, 'minutes').format("YYYY-MM-DDTHH:mm")
    }
    const s_date=((new Date(tempTimeStart).toISOString()));
    const e_date=((new Date(Edate).toISOString()));
    
    if(s_date<new Date().toISOString() || e_date<new Date().toISOString()){
      setLessThanCurrent(true);
      setButtonDisable(false);
    }
    else if(s_date>=e_date)
    {
      setError(true);
      setButtonDisable(false); 
    }
    else{
      console.log(name,s_date,e_date,desc);
      // const val=window.location.href
      // if(val.includes("prescriber_associate")==true)
      // {

      // }
      // else
      // {

      // }
    //   const data = {
    //   CalendarId:window.localStorage.getItem("Id"),
    //   Description:desc,
    //   End:e_date,
    //   EventID: "0",
    //   HCPId: "",
    //   IsFullDay: false,
    //   Start: s_date,
    //   Subject: name,
    //   ThemeColor: ""
    // }
    let param=window.localStorage.getItem('Id').split('-');
    let participantType = {
      ASSPRS : "prescriber_associate",
      PRESCR :"prescriber" 
    };
    const data=
       {
        "Employee_id":parseInt(param[0]),
        "RequestStart_DtTm": s_date,
        "RequestedEnd_DtTm": e_date,
        "Subject_Val": name,
        "Description_Val": desc,
        "Status_Val": "request",
        "Participants": [
            {
                "IsHost_Flg": "Y",
                "Participant_Id":parseInt(param[2]),
                "ParticipantType":participantType[param[4]],
                "Status_Val": "request",
                "ParticipantPhone_Val": "",
                "ParticipantEmail_Val": "",
                "ParticipantCountryCode_Val": ""
            }
        ]
    }
    //   {
    //     "Employee_id": 1000,
    //     "RequestStart_DtTm": "2022-07-16T10:00:00.000Z",
    //     "RequestedEnd_DtTm": "2022-07-16T12:00:00.000Z",
    //     "Subject_Val": "Test Subject1q1",
    //     "Description_Val": "Test Description11q",
    //     "Status_Val": "request",
    //     "Participants": [
    //         {
    //             "IsHost_Flg": "Y",
    //             "Participant_Id": 2003,
    //             "ParticipantType": "prescriber_associate",
    //             "Status_Val": "request",
    //             "ParticipantPhone_Val": "9674647046",
    //             "ParticipantEmail_Val": "souvick.ghosh@p360.com",
    //             "ParticipantCountryCode_Val": "91"
    //         }
    //     ]
    // }
      const headers = {'Content-Type': 'application/json'};
      
      setCreating(true);
      apiHandler('post',(apiList.meetingRequest), data,headers)
      .then(response=>{
        console.log("Result"+ response);
        props.closePopup();
        setCreating(false);
        window.location.reload(false);
        setButtonDisable(false);
      }).catch(e=>{
       setButtonDisable(false);
       setCreating(false);
     })
     
    }
    
   // createform(name,s_date,e_date,desc);
  };

  

  
  async function createform(name,s_date,e_date,desc)
  {

    // var data = JSON.stringify({CalendarId:'06A68980-1097-451D-9549-8C871B1A1C02-T-ED6634B0-AF82-448E-BC9D-407577237F02',
    // Description:desc,
    // End:e_date,
    // EventID: "0",
    // HCPId: "",
    // IsFullDay: false,
    // Start: s_date,
    // Subject: name,
    // ThemeColor: ""})
      
      
      
    //   var config = {
    //   method: 'post',
    //   url: 'https://cordastinv3scheduler.azurewebsites.net/api/Calendar/MeetingRequest/',
    //   headers: {
    //   'Content-Type': 'application/json',
    //   'Cookie': 'ARRAffinity=2f9e3b505044b367fc67f22b9c9e3359209985a215bdb3b02900b7a61ccef076; ARRAffinitySameSite=2f9e3b505044b367fc67f22b9c9e3359209985a215bdb3b02900b7a61ccef076'
    //   },
    //   data : data
    //   };
      
      
      
    //   axios(config)
    //   .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {
    //   console.log(error);
    //   });
    
    // return api('POST','/api/Calendar/MeetingRequest', JSON.stringify({CalendarId:'06A68980-1097-451D-9549-8C871B1A1C02-T-ED6634B0-AF82-448E-BC9D-407577237F02',Description:desc,End:e_date,EventID: "0",
    // HCPId: "",
    // IsFullDay: false,
    // Start: s_date,
    // Subject: name,
    // ThemeColor: ""}))
    // .then(response=>setformResult(response.data));

    //   await fetch('https://cordastinv3scheduler.azurewebsites.net/api/Calendar/MeetingRequest/', {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({CalendarId:'06A68980-1097-451D-9549-8C871B1A1C02-T-ED6634B0-AF82-448E-BC9D-407577237F02',Description:desc,End:e_date,EventID: "0",
    //   HCPId: "",
    //   IsFullDay: false,
    //   Start: s_date,
    //   Subject: name,
    //   ThemeColor: ""})
    // }).then(res => console.log(res.json()))
    
      // const data = await response.json();
      // console.log(data);
      // return data;
  }
  // console.log(formresult);
//   const data=JSON.stringify({CalendarId:'06A68980-1097-451D-9549-8C871B1A1C02-T-ED6634B0-AF82-448E-BC9D-407577237F02',Description:desc,End:e_date,EventID: "0",
//                               HCPId: "",
//                               IsFullDay: false,
//                               Start: s_date,
//                               Subject: name,
//                               ThemeColor: ""})
//   var request = new XMLHttpRequest();
// request.open('POST', 'https://cordastinv3scheduler.azurewebsites.net/api/Calendar/MeetingRequest/', true);
// request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
// request.send(data);


const validateDateTime=(value)=>{

  if(new Date(value)=="Invalid Date" || !value)
  {
    setInValidDate(true);
    setButtonDisable(true); 
  }
  else{
    setInValidDate(false);
    setButtonDisable(false); 
  }
} 
function onChangeValue(event) {
  setEndTimeRadio(event.target.value);
  setError(false);setLessThanCurrent(false);   
}
useEffect(() => {
  if(props.date){
  // setStartDate(new Date(props.date).toISOString().slice(0,11)+"00:00");
  if(props.startTime){
    setTempTimeStart(new Date(props.startTime))
  }
  else{
    setTempTimeStart(new Date(new Date(props.date).toISOString().slice(0,11)+"00:00"))
  }
  if(props.endTime){
    setEndTimeRadio('other')
    setTempTimeEnd(new Date(props.endTime))
  }
  else
  setTempTimeEnd(new Date(new Date(props.date).toISOString().slice(0,11)+"00:00"))
  // setEndDate(new Date(props.date).toISOString().slice(0,11)+"00:00");
  }
  else{
    setTempTimeStart(new Date(new Date().toISOString().slice(0,11)+"00:00"))
  setTempTimeEnd(new Date(new Date().toISOString().slice(0,11)+"00:00"))
    // setStartDate(new Date().toISOString().slice(0,11)+"00:00");
    // setEndDate(new Date().toISOString().slice(0,11)+"00:00");
  }
}, [])

  return (
    <>
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="input-text mb-4">
          <input id="subject" type="text" maxLength={150} placeholder="Subject" value={name} 
          onChange={(e) =>{

              const result = e.target.value.replace(/[^A-Za-z0-9 _.]/gi, '');
             setName(result)

          }
           } required />
          <label> Subject</label>
        </div>
        <div className="input-text mb-4 availability">
          <label> Availability</label>
          <div className="availability-input">
            <div>
              <label>Start:</label>
              <div className="flex">
              <DatePicker
      calendarClassName="datePickerNew"
      selected={tempTimeStart}
      minDate={new Date()}
      maxDate={new Date(new Date().setMonth(new Date().getMonth() + 12))}
      //minTime={new Date()}
      // maxTime={new Date(new Date().setMonth(new Date().getMonth() + 12))}
      dateFormat="MM/dd/yyyy"
      timeIntervals={5}
      onChange={(e) => {validateDateTime(e); setError(false);setLessThanCurrent(false); setTempTimeStart(e)}}
      //timeClassName={handleColor}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      // withPortal={isMobile}
    />
 
    <TimePicker

          value={moment(tempTimeStart, "hh:mm")}
          showSecond={false}
          use12Hours={true}
          minuteStep={5}
          allowEmpty={false}
          onChange={(e)=>setTempTimeStart(new Date(moment(tempTimeStart).format("YYYY-MM-DD")+"T"+moment(e).format("HH:mm")))}
        />
              </div>
            
            {/* <input required type="datetime-local" id="meeting-time-start" step={"900"} name="meeting-time" max={new Date(new Date().setMonth(new Date().getMonth() + 12)).toISOString().slice(0,16)} min={new Date().toISOString().slice(0,11)+"00:00"} value={startdate} onChange={(e) => {validateDateTime(e.target.value); setError(false);setLessThanCurrent(false); setStartDate(e.target.value)}}/> */}
            </div>
            <div onChange={onChangeValue} className="radioEndTime">
     <div> <input type="radio" value="15" name="endTimeRadio" checked={endTimeRadio === "15"} /> 15 mins</div>
    <div>  <input type="radio" value="30" name="endTimeRadio" checked={endTimeRadio === "30"}/> 30 mins</div>
     <div> <input type="radio" value="60" name="endTimeRadio" checked={endTimeRadio === "60"} /> 1 hour</div>
   <div>   <input type="radio" value="other" name="endTimeRadio" checked={endTimeRadio === "other"} /> Other</div>
    </div>
    {
      endTimeRadio == "other"?
      <div>
            <label >End:</label>
            <div className="flex">
            <DatePicker
      
      calendarClassName="datePickerNew "
      selected={tempTimeEnd}
      minDate={new Date()}
      maxDate={new Date(new Date().setMonth(new Date().getMonth() + 12))}
      //minTime={new Date()}
      // maxTime={new Date(new Date().setMonth(new Date().getMonth() + 12))}
      dateFormat="MM/dd/yyyy"
      timeIntervals={5}
      onChange={(e) => {validateDateTime(e); setError(false);setLessThanCurrent(false); setTempTimeEnd(e)}}
      //timeClassName={handleColor}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      // withPortal={isMobile}
    />
    <TimePicker
    
    value={moment(tempTimeEnd, "hh:mm")}
    showSecond={false}
    use12Hours={true}
    minuteStep={5}
    
    allowEmpty={false}
    onChange={(e)=>setTempTimeEnd(new Date(moment(tempTimeEnd).format("YYYY-MM-DD")+"T"+moment(e).format("HH:mm")))}
  />
            </div>
           
             {/* <input required type="datetime-local" id="meeting-time-end" name="meeting-time" max={new Date(new Date().setMonth(new Date().getMonth() + 12)).toISOString().slice(0,16)} min={startdate?new Date(startdate.slice(0,10)).toISOString().slice(0,11)+"00:00":new Date().toISOString().slice(0,11)+"00:00"} value={enddate} onChange={(e) => {validateDateTime(e.target.value); setError(false);setLessThanCurrent(false); setEndDate(e.target.value)}}/> */}
             </div>:null
    }
            
             {
           inValidDate?
           <div id="error" style={{color:"red"}}>Invalid Date-time.</div>
           :
           null
         }
            
             {
           error?
           <div id="error" style={{color:"red"}}>End date-time cannot be less or equal than start date-time.</div>
           :
           null
         }
         {
           lessThanCurrent?
           <div id="error" style={{color:"red"}}>Date-time cannot be less than current date-time.</div>
           :
           null
         }
         </div>
        
         
        </div>
        <div className="input-text mb-4">
          <textarea name="description" maxLength={150} id="" cols="30" rows="1" value={desc} 
          onChange={(e) => 
            {
              const result = e.target.value.replace(/[^A-Za-z0-9 _.]/gi, '');
                setDesc(result)

            }
            }></textarea>
          <label> Description</label>
        </div>
        <div className="form-footer-container">

          <button type="submit" className="footer-btn mb-5" disabled={buttonDisable} >
          {creating?<span>Creating</span>:<span>Create</span>} 
          </button>
        </div>
      </div>
    </form>
    </>
  );
}
